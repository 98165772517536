/* Helper stylesheet for layout.*/

/* #### HEIGHT ##### */

.full-height {
    height: 100%;
}

.h90 {
    height: 90%;
}

.h80 {
    height: 80%;
}

.h70 {
    height: 70%;
}

.h60 {
    height: 60%;
}

/* #### WIDTH ##### */

.w100 {
    width: 100px;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.querter-width {
    width: 25%;
}

/* #### VISIBILITY #### */

.hidden {
    display: none;
}

/* #### MARGIN #### */

.m0 {
    margin: 0
}

.mb0 {
    margin-bottom: 0px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb60 {
    margin-bottom: 60px;
}

.mt0 {
    margin-top: 0px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mt40 {
    margin-top: 40px;
}

.mt50 {
    margin-top: 50px;
}

.mt60 {
    margin-top: 60px;
}

.mr10 {
    margin-right: 10px;
}

.mr20 {
    margin-right: 20px;
}

.mr30 {
    margin-right: 30px;
}

.mr40 {
    margin-right: 40px;
}

.mr50 {
    margin-right: 50px;
}

.ml10 {
    margin-left: 10px;
}

.ml20 {
    margin-left: 20px;
}

.ml30 {
    margin-left: 30px;
}

.ml40 {
    margin-left: 40px;
}

.ml50 {
    margin-left: 50px;
}

.mrml-auto {
    margin-left: auto;
    margin-right: auto;
}

/* #### PADDING #### */

.p10 {
    padding: 10px;
}

.p20 {
    padding: 20px;
}

.p30 {
    padding: 30px;
}

.p40 {
    padding: 40px;
}

.pl5 {
    padding-left: 5px;
}

.pr5 {
    padding-right: 5px;
}

.pl10 {
    padding-left: 10px;
}

.pr10 {
    padding-right: 10px;
}

/* #### COLOR #### */

.no-background {
    background-color: transparent;
}

.bg-semi-transparant {
    background-color: rgba(255, 255, 255, 0.8);
}

/* ### TEXT-TRANSFORMATIONS ### */

.uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.white-text {
    color: white;
}

.grey-text {
    color: rgba(0, 0, 0, 0.38)
}

.red-text {
    color: red
}

.text-center {
    text-align: center;
}

.text-bold {
    font-family: Palanquin-Bold, sans-serif;
}

.text-lg-semi-bold {
    font-size: 1.2em;
    font-family: Palanquin-SemiBold, sans-serif;
}

.text-regular {
    font-size: 1.2em;
    font-family: Palanquin-Regular, sans-serif;
}

.text-regular-l {
    font-family: Palanquin-Regular, sans-serif;
    font-size: 1.3em
}

.text-regular-sm {
    font-family: Palanquin-Regular, sans-serif;
}

.text-regular-xs {
    font-size: 0.8em;
    font-family: Palanquin-Regular, sans-serif;
}

.text-normal {
    font-family: Palanquin, sans-serif
}

.text-semi-bold {
    font-family: Palanquin-SemiBold, sans-serif;
    font-size: 1em;
}

.text-xs-semi-bold {
    font-family: Palanquin-SemiBold, sans-serif;
    font-size: 0.8em;
}

.text-xs {
    font-size: 0.8em;
}

.modal-header {
    font-family: Palanquin-SemiBold, sans-serif;
    text-transform: uppercase;
    color: white;
}

/* ### LAYOUT ### */

.flex {
    display: flex
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-justify {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-col-justify {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-row-space {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.float-right {
    float: right;
}

.vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.overflow-y-scroll {
    overflow-y: scroll;
}