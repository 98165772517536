@font-face {
    font-family: 'Museo-Sans-500';
    src: url('./assets/fonts/museosansrounded-500.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo-Sans-700';
    src: url('./assets/fonts/museosansrounded-700.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Museo-Sans-900';
    src: url('./assets/fonts/museosansrounded-900.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

html, body {
    margin: 0;
    height: 100%;
    padding: 0;
    font-family: 'Museo-Sans-500', sans-serif;
    font-size: 1em;
    min-width: 320px;
    background-color: #FAFAFA;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.entity-container {
    display: inline-block;
    height: 100%;
    width: 50%;
    margin-bottom: 20px;
}

@media (max-width: 1200px) {
    .entity-container {
        display: inline-block;
        height: 100%;
        width: 70%;
    }
}

@media (max-width: 800px) {
    .entity-container {
        display: inline-block;
        height: 100%;
        width: 100%;
    }
}

.dialog-root {
    padding-top: 0 !important;
}

.img-clear-btn {
    color: #bdbdbd !important;
}

.img-clear-btn:hover {
    color: #ef5350 !important;
}


h2 {
    font-family: 'Museo-Sans-900', sans-serif;
}

h3 {
    font-family: 'Museo-Sans-900', sans-serif;
}

#root {
    height: 100%;
    display: flex;
    flex-flow: column;
}