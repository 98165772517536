input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #4da944;
}

.login-paper {
    width: 550px;
    height: 650px;
    min-width: 300px;
    max-width: 600px;
    display: block;
    margin: 0 auto;
    background-color: #272c36;
}

.center {
    margin: 0px auto;
    width: 60%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .login-paper {
        width: 100%;
    }

    .center {
        width: 75%;
    }
}

.provider-btn {
    width: 110px;
    margin: 5px;
}

.signin-text {
    color: white;
}

.login-container {
    height: 100%;
    background-size: cover;
    background: url("../../assets/img/background.jpg") no-repeat center;
    display: flex;
    align-items: center;
}

.bg-overlay {
    background: rgba(82, 92, 111, 0.60);
}

.loginPaper {
    /*width: 50%;*/
    /*height: 100%;*/
}

.signin-text {
    font-size: 4em;
    font-weight: 100;
}

.dynamo-logo {
    position: fixed;
    right: 5px;
}

.full-width {
    width: 100%;
    min-width: 210px;
}

.signin-text {
    font-size: 2em;
}

.logo {
    display: block;
    width: 100%;
    height: 100%;
}

.or-text {
    position: relative;
    font-size: 0.75em;
    color: white;
    margin: 20px 0px;
    z-index: 1;
}

.or-text::before {
    border-top: 2px solid #ffffff;
    opacity: 0.3;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.or-text span {
    padding: 0px 10px;
    text-transform: uppercase;
    background-color: #272c36;
}

.dynamo-logo {
    display: block;
    bottom: 5px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.create-container {
    font-size: 0.75em;
    color: white;
    position: absolute;
    bottom: 10px;
}
